<template>
  <div id="activity">
    <AllActivity v-if="!showAddGoods" @handleEditActivity="handleEditActivity" />
    <AddActivity
      v-else
      :activityInfo="activityInfo"
      :showAddGoods="showAddGoods"
      @handleSuccess="handleSuccess"
    />
  </div>
</template>

<script>
import AllActivity from "./components/all_activity.vue";
import AddActivity from "./components/add_activity.vue";

export default {
  name: "order",
  components: {
    AllActivity,
    AddActivity,
  },
  data() {
    return {
      activityInfo: null, // 活动
      showAddGoods: false,
    };
  },
  methods: {
    handleEditActivity(activityInfo) {
      this.showAddGoods = true;
      this.activityInfo = activityInfo;
    },
    handleSuccess() {
      this.showAddGoods = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
