<template>
  <div class="text-msg-pic-upload">
    <el-upload
      :class="{ display: uploadDisabled }"
      list-type="picture-card"
      ref="upload"
      action
      multiple
      :http-request="handleUpload"
      :auto-upload="autoUpload"
      :limit="limit"
      :file-list="tempFileList"
      :on-exceed="handleExceed"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-preview="handlePictureCardPreview"
      accept="jpg,.jpeg,.png,.JPG,.JPEG"
    >
      <i class="el-icon-plus"></i>
      <!-- <div slot="tip" class="el-upload__tip" v-if="tipsFlag">{{ tips }}</div> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <div class="Upload_pictures" v-if="tipsFlag">
      <ul class="el-upload__tip cBBBDBF" style="color: #BBBDBF;">
        <li>{{ tips }}</li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import upload from "@/utils/fileUpload.js";
export default {
  name: "UploadImageDemo",
  props: {
    width: {
      type: String,
      default: "240px",
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 1,
    },
    limitType: {
      type: Array,
      default: () => ["image/jpeg", "image/png", "image/jpg"],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imgList: {
      type: Array,
      default: () => [],
    },
    tipsFlag: {
      type: Boolean,
      default: true,
    },
    tips: {
      type: String,
      default: "支持PNG、JPG、JEPG格式；建议尺寸800*235",
    },
  },
  data() {
    return {
      // 上传文件列表，el-upload使用，临时保存数据。
      tempFileList: this.imgList,
      host: "", // 阿里云上传服务器地址根路径
      uploadDisabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  watch: {
    // 解决第二渲染接口， 图片还保留着原来的问题 JerryYi
    imgList: {
      immediate: true,
      handler(val) {
        this.tempFileList = val;
      },
    },
  },
  computed: {
    upText() {
      return this.autoUpload ? "上传文件" : "选择文件";
    },
  },
  created() {},
  mounted() {},
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      // console.log('beforeUpload', file)
      let types = this.limitType;
      const isImage = types.includes(file.type);
      const isLt20M = file.size / 1024 / 1024 < 10;

      if (!isImage) {
        this.$message({
          message:
            types.length == 0
              ? "上传图片只能是 PNG 格式!"
              : "上传图片只能是 JPG、PNG 格式!",
          type: "warning",
        });
        return false;
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }
      return isImage && isLt20M;
    },
    // 自定义上传操作
    handleUpload(op) {
      let dir = `images`;
      upload(
        op.file,
        (res) => {
          let temp = {
            name: res.attachment,
            url: res.host + "/" + res.aliyunAddress,
          };
          this.host = res.host;
          op.onSuccess(temp);
        },
        (err) => {
          console.log(err);
        },
        (res) => {
          op.onProgress(res);
        },
        dir
      );
    },
    // 上传成功后触发
    handleSuccess(response, file, fileList) {
      this.filterFileFn(fileList);
    },
    // 返回给接口要用的格式
    filterFileFn(fileList) {
      let filterArr = fileList
        .filter((item) => !item.status || item.status !== "ready") // 过滤未上传的文件
        .map((item) => {
          let url = item.response ? item.response.url : item.url;
          return {
            url: url, // item.url || item.response.url
            name: item.name,
          };
        });
      console.log('fileList', fileList)
      this.$emit("onSuccessFiles", filterArr);
    },
    // 监听移除文件列表
    handleRemove(file, fileList) {
      if (file.status === "success") {
        this.filterFileFn(fileList);
      }
    },
    handleExceed(files, fileList) {
      this.$message({
        message: `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        type: "warning",
      });
    },
    beforeRemove() {
      // return this.$confirm(`确定移除 ${file.name}？`)
    },
  },
};
</script>

<style>
.text-msg-pic-upload .el-upload--picture-card,
.text-msg-pic-upload .el-upload-list--picture-card .el-upload-list__item {
  width: 140px;
  height: 140px;
}
.display .el-upload--picture-card {
  display: none;
}
</style>
