<template>
  <div id="addGoods">
    <el-button
      type="text"
      icon="el-icon-arrow-left"
      @click="handleBack"
      class="back"
      >返回</el-button
    >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px"
      size="mini"
    >
      <el-form-item label="活动名称" prop="promotion_name">
        <el-input
          v-model.trim="form.promotion_name"
          class="from-input-350"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动时间" prop="activity_time">
        <el-date-picker
          v-model.trim="form.activity_time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="from-input-350"
          format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动门店" prop="shop_id">
        <el-select placeholder="请选择" v-model.trim="form.shop_id">
          <el-option
            v-for="item in shopList"
            :key="item.shop_id"
            :label="item.shop_name"
            :value="item.shop_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="折扣率" prop="discount">
        <el-input v-model="form.discount" class="from-input-180"></el-input>
        <span style="margin-left:10px;color:#555">%</span>
      </el-form-item>
      <el-form-item label="展示图" prop="ad_pic">
        <UploadImg :imgList="fileList" @onSuccessFiles="handleUploadSuccess" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :disabled="requesting"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import UploadImg from "@/components/uploadImg.vue";

export default {
  components: {
    UploadImg,
  },
  props: ["activityInfo"],
  data() {
    return {
      form: {
        promotion_name: "",
        activity_time: "",
        discount: "",
        ad_pic: "",
        shop_id: "",
      },
      rules: {
        promotion_name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        activity_time: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        discount: [
          { required: true, message: "请输入折扣率", trigger: "blur" },
        ],
        ad_pic: [{ required: true, message: "请上传展示图" }],
        shop_id: [{ required: true, message: "请选择门店" }],
      },
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      shopList: [],
      requesting: false,
    };
  },
  created() {
    this.getShopList();
    if (this.activityInfo?.shop_id) {
      this.setActivityDetail();
    }
  },
  methods: {
    getShopList() {
      this.$http
        .get("smart_shops")
        .then((res) => {
          console.log("res", res);
          this.shopList = res;
        })
        .catch((err) => {});
    },
    // 获取活动详情-组装数据
    setActivityDetail() {
      const defaultInfo = this.activityInfo;
      this.form = {
        promotion_name: defaultInfo.promotion_name,
        activity_time: [
          defaultInfo.begin_time * 1000,
          defaultInfo.end_time * 1000,
        ],
        shop_id: defaultInfo.shop_id,
        discount: defaultInfo.discount,
        ad_pic: defaultInfo.ad_pic,
      };
      this.fileList = [{ url: defaultInfo.ad_pic }];
    },
    handleUploadSuccess(res) {
      this.fileList = res;
      this.form = {
        ...this.form,
        ad_pic: res[0]?.url,
      };
    },
    //  提交
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let { discount, activity_time, shop_id } = this.form;
          if (Number(discount) <= 0 || Number(discount) >= 100) {
            this.$message({
              type: "error",
              duration: 2000,
              message: `折扣率需大于0！小于100`,
            });
            return;
          }

          let _url = this.activityInfo?.promotion_id
            ? `/smart_promotion/${this.activityInfo.promotion_id}`
            : "/smart_promotion";

          const shopInfo = this.shopList.find((i) => i.shop_id === shop_id);
          const params = {
            promotion_name: this.form.promotion_name,
            ad_pic: this.form.ad_pic,
            begin_time: dayjs(activity_time[0]).unix(),
            end_time: dayjs(activity_time[1]).unix(),
            discount: Number(Number(discount).toFixed(0)),
            shop_id: shopInfo?.shop_id,
            shop_name: shopInfo?.shop_name,
          };
          console.log("params", params);
          this.requesting = true;
          this.$http
            .post(_url, params)
            .then((res) => {
              this.$message({
                type: "success",
                duration: 2000,
                message: "保存成功！",
              });
              this.requesting = false;
              this.$emit("handleSuccess");
            })
            .catch((err) => {
              console.log(err.message);
             
              this.requesting = false;
              this.$message({
                type: "error",
                duration: 2000,
                message: err.message+ ", 保存失败，请重试！ " ,
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      console.log("submit!");
    },
    resetForm() {
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    handleBack() {
      this.$emit("handleSuccess");
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  top: 87px;
  left: 260px;
  color: #777;
}
.from-input-350 {
  width: 350px;
}
.from-input-180 {
  width: 180px;
}
.customUpload {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}
</style>
