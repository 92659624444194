<template>
  <div id="allGoods">
    <ekk-fliter
      placeholder="活动"
      :noExport="true"
      :noStatus="true"
      :noDate="true"
      @keyword="filters.search"
      :otherBtn="filters.otherBtn"
      @handleBtn="filters.handleBtn"
    />
    <ekk-table
      :tableData="viewData.tableData"
      :options="viewData.options"
      :page="viewData.page"
      :feedback="viewData.feedback"
      @handle="viewData.handle"
      @handlePage="viewData.handlePage"
      @handleSort="viewData.handleSort"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import util from "@/utils/util";
import ekkTable from "@/components/ekk_table.vue";
import ekkFliter from "@/components/ekk_fliter.vue";

export default {
  name: "record",
  components: {
    ekkTable,
    ekkFliter,
  },
  data() {
    return {
      keyword: "",
      filters: {
        // 关键字筛选
        search: (val) => {
          setTimeout(() => {
            [this.keyword, this.viewData.page.cur] = [val, 1];
            this.getList();
          }, 300);
        },
        otherBtn: {
          show: true,
          text: "发布活动",
        },
        handleBtn: () => {
          this.$emit("handleEditActivity");
        },
      },
      viewData: {
        options: [
          { prop: "ID", label: "活动ID", width: 60, overflow: true },
          {
            prop: "activity_time",
            label: "活动时间",
            width: 160,
          },
          {
            prop: "promotion_name",
            label: "活动名称",
            width: 200,
          },
          {
            prop: "shop_name",
            label: "活动门店",
            width: 140,
          },
          { prop: "discountTitle", label: "折扣率", width: 60, overflow: true },
          {
            prop: "ad_pic",
            label: "展示图",
            width: 100,
            overflow: true,
            src: true,
          },
          { prop: "statusShow", label: "状态", width: 60, overflow: true },
          { prop: "created_at", label: "创建时间", width: 120, overflow: true },
          {
            label: "操作",
            width: 120,
            center: true,
            slot: [
              { type: "edit", name: "编辑" },
              { type: "delete", name: "删除" },
              {
                type: "status",
                condition: "statusShow",
                conditionList: {
                  预告中: "禁用",
                  进行中: "禁用",
                  禁用: "启用",
                },
              },
            ],
          },
        ],
        tableData: [],
        page: { cur: 1, size: 20, total: 0 },
        feedback: "",
        // 列表操作
        handle: (row, type) => {
          switch (type) {
            case "edit":
              console.log(row);
              this.$emit("handleEditActivity", row);
              break;
            case "delete":
              console.log(row);
              this.handleDelete(row.promotion_id);
              break;
            case "status":
              console.log(row);
              this.handleUpdateStatus(row);
              break;
            default:
              break;
          }
        },
        // 页码切换
        handlePage: (val) => {
          this.viewData.page.cur = val;
          this.getList();
        },
        // 列表排序
        handleSort: (prop, order) => {
          // let _self = this;
          // _self.stockList.page.cur = 1;
          // _self.order = prop ? prop : '',
          // _self.sort = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
          // _self.getList();
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //获取列表
    getList() {
      this.viewData.feedback = "loading";
      this.viewData.tableData = [];

      this.$http
        .get("smart_promotions", {
          params: {
            search: this.keyword,
            page: this.viewData.page.cur,
            pagesize: this.viewData.page.size,
          },
        })
        .then((res) => {
          let { lists, total } = res;
          let _listCopy = [];
          if (lists.length > 0) {
            lists.forEach((item, index) => {
              const beginTime = dayjs(item.begin_time * 1000).format("YYYY-MM-DD HH:mm");
              const endTime = dayjs(item.end_time * 1000).format("YYYY-MM-DD HH:mm");
              let statusShow = "禁用";
              if (item.status === 1) {
                if (dayjs().isBefore(dayjs(beginTime))) statusShow = "预告中"
                else if (dayjs().isAfter(dayjs(endTime))) statusShow = "已结束"
                else statusShow = '进行中'
              }
              _listCopy.push({
                ...item,
                activity_time: `${beginTime} ~ ${endTime}`,
                created_at: dayjs(item.created_at).format("YYYY-MM-DD HH:mm"),
                statusShow,
                discountTitle: `${item.discount}%`,
              });
            });
            this.viewData.feedback = "";
          } else {
            this.viewData.feedback = "empty";
          }
          this.viewData.tableData = _listCopy;
          this.viewData.page.total = total;
        })
        .catch((err) => {
          this.viewData.feedback = "error";
        });
    },
    // 删除商品
    handleDelete(promotionId) {
      this.$confirm(`确定要删除该商品？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .delete(`smart_promotion/${promotionId}`, {
              params: {
                promotion_id: promotionId,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                duration: 2000,
                message: "删除成功！",
              });
              this.getList();
            })
            .catch((err) => {
              this.$message({
                type: "error",
                duration: 2000,
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    // 修改活動狀態
    handleUpdateStatus(row) {
      this.$confirm(
        `确定要${row.status === 1 ? "禁用" : "启用"} ${
          row.promotion_name
        } 活动？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$http
            .post(`/smart_promotion/status`, {
              promotion_id: row.promotion_id,
              status: row.status === 1 ? 2 : 1,
            })
            .then((res) => {
              this.$message({
                type: "success",
                duration: 2000,
                message: "修改成功！",
              });
              this.getList();
            })
            .catch((err) => {
              this.$message({
                type: "error",
                duration: 2000,
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
